<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay>
    <template v-slot:activator="{ on, attr }">
      <v-btn v-bind="attr" v-on="on" :loading="loading" bottom class="center-x" color="primary" fixed @click="start">
        {{
          inspection.isDone()
            ? $vuetify.lang.t('$vuetify.general.inspection.siteDialog.viewInspection')
            : $vuetify.lang.t('$vuetify.general.inspection.siteDialog.startInspection')
        }}
      </v-btn>
    </template>
    <v-card tile>
      <v-app-bar color="primary" dark fixed flat>
        <v-btn dark icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer/>
        <v-toolbar-title :style="{ 'margin-left': '-24px' }">
          <v-progress-circular v-if="loading" indeterminate/>
          <template v-else>
            {{ inspection.site.name }} #{{ inspection.branch.number }}
          </template>
        </v-toolbar-title>
        <v-spacer/>
        <template v-slot:extension>
          <v-card color="white" height="48" tile width="100%">
            <v-row no-gutters>
              <v-col cols="12">
                <span
                  class="black--text text-body-2 font-weight-bold">{{
                    $lodash.has(form, 'title') ? form.title : ''
                  }}</span>
              </v-col>
              <v-col cols="12">
                <span
                  class="black--text text-body-2">
                  {{ $lodash.has(form, 'pages') ? form.pages[$refs.form.currentPage].title : '' }}</span>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </v-app-bar>
      <kurcc-form ref="form" :inspection="inspection" :loading="loading" class="inspection__content pt-3"
                  @done="close"/>
    </v-card>
  </v-dialog>
</template>

<script>
import Inspection from '@/modules/inspections/models/inspection'
import { RESET_INSPECTION_FORM } from '@/modules/app/store/mutation-types'

export default {
  name: 'KurccInspection',
  props: {
    inspection: {
      type: Inspection,
      required: true
    }
  },
  components: {
    KurccForm: () => import('@/modules/inspection/components/KurccForm')
  },
  data () {
    return {
      dialog: false,
      loading: false
    }
  },
  computed: {
    form () {
      return this.$store.state.inspection.form
    }
  },
  methods: {
    close () {
      this.change()
      this.dialog = false
      this.$store.commit(RESET_INSPECTION_FORM)
    },
    change () {
      this.$emit('change')
    },
    start () {
      this.loading = true
      if (this.inspection.result_id) {
        this.getForm().finally(() => {
          this.loading = false
        })
      } else {
        this.startInspection().finally(() => {
          this.loading = false
        })
      }
    },
    getForm () {
      return this.$store.dispatch('getForm', {
        id: this.inspection.id,
        form_id: this.inspection.result_id
      })
    },
    startInspection () {
      return this.$store.dispatch('startInspection', { id: this.inspection.id })
    }
  }
}
</script>

<style lang="scss">
.v-toolbar__extension {
  padding: 0 !important;
}

.inspection__content {
  margin-top: 104px;
}
</style>
